<template>
  <v-container>
    <v-row>
      <v-card-subtitle class="pt-10 col col-12 headline text-uppercase grey--text">
        {{ profileCode === 'PR' ? $t('open_process') : $t('general_projects') }}
      </v-card-subtitle>
      <v-col
        cols="12"
        class="pt-1"
      >
        <v-divider class="pa-0 mb-4" />
      </v-col>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
        lg="12"
      >
        <template v-if="listProjects === null || listProjects.length !== 0">
          <card-project
            v-for="(projectInfo, index) in listProjects"
            :key="index"
            :project-info="projectInfo"
            class="my-2"
          />
        </template>
        <template v-else>
          <div>
            <h3 v-if="profileCode === 'PR'">
              {{ $t('without_open_projects') }}
            </h3>
            <h3 v-else>
              {{ $t('without_projects') }}
            </h3>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CardProject from '@/components/uiComponents/cards/cardProject/CardProject'
import { URL_LIST_PROJECTS, URL_ALL_PROJECTS } from './data/projectsData'
import { mapState } from 'vuex'
import momentoTimezone from 'moment-timezone'
import handleErrorMixin from '@/mixins/handleErrorMixin'
import moment from 'moment'

export default {
  name: 'Projects',
  components: {
    CardProject
  },
  mixins: [handleErrorMixin],
  data () {
    return {
      listProjects: null,
      profileCode: null
    }
  },
  computed: {
    /**
     * This property computed get userInfo objet that response back
     *
     * @author Andres Correa
     * @since 18/05/2020 3:20 PM
     * @version 1.0.0
     */
    ...mapState({ userInfo: (state) => state.security.userInfo })
  },
  created () {
    this.profileCode = this.userInfo.profileCode
    this.getListProjects()
  },
  methods: {
    /**
     * This method get projects
     *
     * @author Andres Correa
     * @since 18/05/2020 3:20 PM
     * @version 1.0.0
     */
    getListProjects () {
      this.axios.get(this.profileCode === 'AD' ? URL_ALL_PROJECTS : URL_LIST_PROJECTS, null, { enableSpinner: true })
        .then((response) => {
          this.listProjects = response.data
        }).catch((error) => {
          this.$_handleErrorMixin_generateError(error)
        })
    },
    momentoTimezone,
    moment
  }
}
</script>
<i18n>
{
  "es": {
    "closed_process": "Proyectos finalizados",
    "open_process": "Procesos abiertos",
    "general_projects":"Proyectos",
    "without_projects": "Actualmente no hay proyectos finalizados",
    "without_open_projects": "Actualmente no hay proyectos abiertos o no se han iniciado"
  }
}
</i18n>
