<template>
  <v-card outlined>
    <v-container
      fluid
      class="d-flex"
    >
      <v-col
        cols="1"
        align-self="center"
      >
        <v-card-actions>
          <v-btn
            height="70px"
            width="70px"
            class="bottom-details secondary"
            @click="openDetails"
          >
            <v-icon
              color="primary"
              size="46px"
              light
            >
              {{ getIcon() }}
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="profileCode == 'AD'">
          <v-btn
            height="70px"
            width="70px"
            class="bottom-details secondary"
            :disabled="!validateStartDate"
            @click="openStageConfiguration"
          >
            <v-icon
              color="primary"
              size="46px"
              light
            >
              fal fa-wrench
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
      <v-col
        cols="7"
        class="p-0 m-2"
      >
        <h3 class="headline font-weight-bold text-uppercase">
          {{ projectInfo.tenderName }}
        </h3>
        <h4 class="text-uppercase grey--text">
          {{ projectInfo.tenderOwner }}
        </h4>
        <p>{{ projectInfo.tenderDescription }}</p>
      </v-col>
      <v-col
        cols="4"
        class="mt-4"
      >
        <v-card-text class="d-flex p-none">
          <p class="mt-1 mb-1 mr-2 col cols-4 text-right p-none">
            <span class="font-weight-medium">Plazo:</span>
          </p>
          <p class="mt-1 mb-1 col cols-8 text-center p-none">
            {{ projectInfo.contractTerm }}
          </p>
        </v-card-text>
        <div class="d-flex v-card__text p-none">
          <p class="mt-1 mb-1 mr-2 col cols-4 text-right p-none">
            <span class="font-weight-medium">Numero de proceso:</span>
          </p>
          <p class="mt-1 mb-1 col cols-8 text-center p-none">
            {{ projectInfo.processNumber }}
          </p>
        </div>
      </v-col>
    </v-container>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
export default {
  name: 'CardProject',
  props: {
    projectInfo: {
      type: Object,
      required: true,
      description: 'Objeto con la informacion del proyecto'
    }
  },
  data () {
    return {
      profileCode: null
    }
  },
  computed: {
    /**
     * This property computed get userInfo objet that response back
     *
     * @author Andres Correa
     * @since 18/05/2020 3:20 PM
     * @version 1.0.0
     */
    ...mapState({ userInfo: (state) => state.security.userInfo }),
    validateStartDate () {
      let result = (moment.tz(this.projectInfo.tenderStartDate * 1000, 'Atlantic/Azores').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('X')) - (moment().format('X'))
      return result > 0
    }
  },
  created () {
    this.profileCode = this.userInfo.profileCode
  },
  methods: {
    openDetails () {
      const name = this.userInfo.profileCode === 'PR' ? 'projectDetails' : 'ProjectsReceived'
      this.$router.push({ name, params: { tenderId: this.projectInfo.id } })
    },
    getIcon () {
      return this.profileCode === 'PR' ? 'fal fa-file-signature' : 'fal fa-clipboard-list'
    },
    openStageConfiguration () {
      const name = 'StagesConfiguration'
      this.$router.push({ name, params: { projectInfo: this.projectInfo } })
    }
  }
}
</script>
<style lang="scss">
.bottom-details {
  border-radius: 15%;
}
.p-none {
  padding: 0 !important;
}
</style>
